import React from 'react'
import HomeTemplate from '../components/Home/HomeTemplate'

const Home = () => {
    return (
        <>
            <HomeTemplate />
        </>
    )
}

export default Home